import React from "react";
import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Chat } from "./pages";
import Login  from "./pages/Login/Login"


  function App() {
    // const [isChatbotButtonClicked, setIsChatbotButtonClicked] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false); // State for admin status

    // Session expiration time in milliseconds (e.g., 1 hour)
  const SESSION_TIMEOUT = 2 * 60 * 1000; 

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("expiryTime");
    // Optionally redirect to login page
};


  useEffect(() => {
    const checkAuthStatus = () => {
      const authStatus = localStorage.getItem("isAuthenticated");
      const expiryTime = localStorage.getItem("expiryTime");

      if (authStatus === "true" && expiryTime) {
        const currentTime = new Date().getTime();

        // Check if the session is still valid
        if (currentTime < parseInt(expiryTime, 10)) {
          setIsAuthenticated(true); // Session is valid
        } else {
          // Session has expired
          localStorage.removeItem("isAuthenticated");
          localStorage.removeItem("expiryTime");
          setIsAuthenticated(false); // Reset authentication state
        }
      } else {
        // No valid session
        setIsAuthenticated(false); // Force user to log in again
      }
    };

    checkAuthStatus();
    // Optionally, add an interval to continuously check session status (e.g., every 1 minute)
    const interval = setInterval(checkAuthStatus, 60 * 60 * 1000); // Check every minute
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem("isAuthenticated", "true");

    // Set expiry time
    const expiryTime = new Date().getTime() + SESSION_TIMEOUT;
    localStorage.setItem("expiryTime", expiryTime.toString());
  };
  

    return (
      <Router>
        <Routes>
              {/* Always start on the login page unless the user is authenticated */}
          <Route
            path="/login"
            element={
            isAuthenticated ? <Navigate to="/" /> : <Login setIsAuthenticated={handleLogin} />
            }
          />
          <Route
          path="/"
          element={
            isAuthenticated ? (
              <Chat handleLogout={handleLogout} />
            ) : (
                <Navigate to="/login" />
              )
            }
          />
          
          {/* Fallback route */}
        <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    );
  }

export default App;
