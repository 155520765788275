// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgBtnContainer{
 border: 1px solid var(--border-color);
 border-radius: 50%;
 min-width: -moz-fit-content;
 min-width: fit-content;
 max-width: 8rem;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 0.8rem;
 cursor: pointer;
}

.btnImg{
 width:4rem;
 height: 4rem;
 margin: auto;
 object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/imgButton/imgBtn.css"],"names":[],"mappings":"AAAA;CACC,qCAAqC;CACrC,kBAAkB;CAClB,2BAAsB;CAAtB,sBAAsB;CACtB,eAAe;CACf,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,eAAe;CACf,eAAe;AAChB;;AAEA;CACC,UAAU;CACV,YAAY;CACZ,YAAY;CACZ,mBAAmB;AACpB","sourcesContent":[".imgBtnContainer{\n border: 1px solid var(--border-color);\n border-radius: 50%;\n min-width: fit-content;\n max-width: 8rem;\n display: flex;\n justify-content: center;\n align-items: center;\n padding: 0.8rem;\n cursor: pointer;\n}\n\n.btnImg{\n width:4rem;\n height: 4rem;\n margin: auto;\n object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
