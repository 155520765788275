// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
  display: flex;
  flex-direction: column;
}

#chatbotButton {
  position: fixed;
  bottom: 4%;
  right: 4%;
  z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,SAAS;EACT,UAAU;AACZ","sourcesContent":[".home-page {\n  display: flex;\n  flex-direction: column;\n}\n\n#chatbotButton {\n  position: fixed;\n  bottom: 4%;\n  right: 4%;\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
