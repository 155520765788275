// import { showLoading, showError, setAnswer } from "../features/queryAnsSlice";
// // import { AppDispatch, RootState } from "../app/store";
// // import { AnyAction } from "@reduxjs/toolkit";
// // import { ThunkAction } from "@reduxjs/toolkit";

// // const answerEndpoint = "http://192.168.29.191:5001/chatbot";

// // const answerEndpoint = "https://aman-geotechno.github.io/response";

// //const answerEndpoint = "http://10.0.0.14:5000/response"
// const answerEndpoint = "http://192.168.61.84:5002/response"

// // const answerEndpoint = "https://flask.brlps.in/response"


// // const answerEndpoint = "http://localhost:5051//response";

// export const fetchAnswerMiddleware = (query) => {
//   console.log("fetchmiddleware called");
//   return async(dispatch) => {   
//     try {
//       dispatch(showLoading());
//       // const controller= new AbortController();
//       // const timeOutId=setTimeout(()=>controller.abort(),100);
      
//       const resp = await fetch(answerEndpoint,{
//         method:"POST",
        
//         headers:{
//             "Content-Type":"application/json",
            
//         },
//          body:JSON.stringify({"question":query})
//       });

//       console.log("resp is",resp);

//       //clearTimeout(timeOutId);

//       if(resp.status===200){
//       const data = await resp.json();
//       console.log("data is:",data);
//       dispatch(setAnswer(data.response));
//       }
//       else{
//         throw new Error('Need to modify your question a bit');
//       }
     
//       // setTimeout(()=>
//       // {
//       //   if(!resp)
//       //      throw new Error('Error Timed out');
//       // },1000)

//     } 
//     catch (err) {
//       dispatch(setAnswer(`${err.message}`));
//       dispatch(showError());
//     }  
//   };  
// };


import { showLoading, showError, setAnswer } from "../features/queryAnsSlice";
// import { AppDispatch, RootState } from "../app/store";
// import { AnyAction } from "@reduxjs/toolkit";
// import { ThunkAction } from "@reduxjs/toolkit";

// const answerEndpoint = "http://192.168.29.191:5001/chatbot";

// const answerEndpoint = "https://aman-geotechno.github.io/response";

//const answerEndpoint = "http://10.0.0.14:5000/response";
const answerEndpoint = "https://dms1.geotechnosoft.com/response_dms";

// const answerEndpoint = "https://flask.brlps.in/response";

// const answerEndpoint = "http://localhost:5051//response";

export const fetchAnswerMiddleware = (query) => {
  console.log("fetchmiddleware called");
  return async (dispatch) => {   
    try {
      dispatch(showLoading());

      const resp = await fetch(answerEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: query }),
      });

      console.log("Response status:", resp.status);

      if (resp.status === 200) {
        const data = await resp.json();
        console.log("Response data:", data);

        if (data && data.response) {
          dispatch(setAnswer(data.response));
        } else {
          throw new Error('Invalid response format');
        }
      } else {
        throw new Error(`Server responded with status: ${resp.status}`);
      }
    } catch (err) {
      console.error("Error occurred:", err);
      dispatch(setAnswer(`Error: ${err.message}`));
      dispatch(showError());
    }
  };
};


