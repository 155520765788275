// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    /* position: fixed;
    top:0;
    left:0;
    right:0; */
    width:99%;
    min-width: -moz-fit-content;
    min-width: fit-content;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0.2rem;  
    margin-left: 0.5%;
    margin-right: 0.5%;
    border-radius: 0.4rem; 
    border: 0.02rem solid rgb(221, 213, 213);
    
}

.brand-logo{
    width:7em;
    height:7rem;
    max-width: 7rem;
    max-height: 7rem;
    object-fit: contain;
    margin-left: 1rem;
    background-color: transparent;
}

.brand-text{
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    justify-self: center;
    color: var(--secondary-color);
}

.gov-img-container{
    display: flex;
    margin-left: auto;
    margin-right: 1rem;
}

.gov-img{
    width: 4rem;
    height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    object-fit: contain;
    margin-left: 1rem;
    margin-right: 1rem;
}

`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI;;;cAGU;IACV,SAAS;IACT,2BAAsB;IAAtB,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;IACrB,wCAAwC;;AAE5C;;AAEA;IACI,SAAS;IACT,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".header{\n    /* position: fixed;\n    top:0;\n    left:0;\n    right:0; */\n    width:99%;\n    min-width: fit-content;\n    background-color: white;\n    display: flex;\n    align-items: center;\n    padding: 0.2rem;  \n    margin-left: 0.5%;\n    margin-right: 0.5%;\n    border-radius: 0.4rem; \n    border: 0.02rem solid rgb(221, 213, 213);\n    \n}\n\n.brand-logo{\n    width:7em;\n    height:7rem;\n    max-width: 7rem;\n    max-height: 7rem;\n    object-fit: contain;\n    margin-left: 1rem;\n    background-color: transparent;\n}\n\n.brand-text{\n    text-transform: uppercase;\n    font-size: 2rem;\n    font-weight: bold;\n    justify-self: center;\n    color: var(--secondary-color);\n}\n\n.gov-img-container{\n    display: flex;\n    margin-left: auto;\n    margin-right: 1rem;\n}\n\n.gov-img{\n    width: 4rem;\n    height: 4rem;\n    max-width: 4rem;\n    max-height: 4rem;\n    object-fit: contain;\n    margin-left: 1rem;\n    margin-right: 1rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
