import { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import {
  botIcon,
  speakerIcon,
  userIcon,
  activeSpeakerIcon,
} from "../../assets/icons";
import { askDidiLogo } from "../../assets/images";
import handleSpeechSynthesis from "../../utils/handleSpeechSynthesis";
import { utils, writeFile } from "xlsx";
import "./queryAns.css";

const detectLanguage = (text) => {
  const hindiRegex = /[\u0900-\u097F]/;
  return hindiRegex.test(text) ? "hi-IN" : "en-US";
};

const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

function QueryAns(props) {
  const [isSpeakerClicked, setIsSPeakerClicked] = useState([]);
  const [contentType, setContentType] = useState("");
  const query = useAppSelector((state) => state.queryAnsReducer.query);
  const answer = useAppSelector((state) => state.queryAnsReducer.ans);

  const queryAnsWindowRef = useRef(null);
  const [previousQueryLength, setPreviousQueryLength] = useState(0);

  const initialMessage =
"नमस्कार, मैं आपकी DocAI चैटबॉट हूँ। मैं Jeevika के कार्यालय आदेशों और कार्यालय सलाह से संबंधित जानकारी प्रदान कर सकती हूँ। इसके लिए मैं एक ज्ञानकोष का उपयोग करती हूँ जहाँ सभी PDF और स्कैन किए गए दस्तावेज़ अपलोड किए जाते हैं।"

  const handleSpeakerClick = (htmlContent, index, contentTypeIS) => {
    try {
      const plainText = stripHtmlTags(htmlContent);
      const detectedLanguage = detectLanguage(plainText);
      const utterance = handleSpeechSynthesis(plainText, detectedLanguage);

      const isSpeakerClickedCopy = [...isSpeakerClicked];
      setContentType(contentTypeIS);
      utterance.onstart = () => {
        isSpeakerClickedCopy[index] = true;
        setIsSPeakerClicked([...isSpeakerClickedCopy]);
      };
      utterance.onend = () => {
        isSpeakerClickedCopy[index] = false;
        setIsSPeakerClicked([...isSpeakerClickedCopy]);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (content) => {
    const plainTextContent = stripHtmlTags(content);
    downloadAsExcel(plainTextContent);
  };

  const downloadAsExcel = (content) => {
    const lines = content.split("\n").filter((line) => line.trim() !== "");
    const worksheetData = [];

    // Process the lines to create a structured data array
    for (let i = 0; i < lines.length; i += 2) {
      if (lines[i + 1]) {
        worksheetData.push({
          District: lines[i].trim(),
          Count: lines[i + 1].trim(),
        });
      }
    }

    // Create the worksheet from the structured data array
    const worksheet = utils.json_to_sheet(worksheetData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Answers");
    writeFile(workbook, "answers.xlsx");
  };

  // Scroll to the bottom only when a new query is added
  useEffect(() => {
    if (query.length > previousQueryLength) {
      queryAnsWindowRef.current.scrollTop =
        queryAnsWindowRef.current.scrollHeight;
      setPreviousQueryLength(query.length);
    }
  }, [query, previousQueryLength]);

  return (
    <div
      id={props.id}
      ref={queryAnsWindowRef}
      style={{ overflowY: "auto", height: "100%" }}
    >
      {/* Initial Message at the top */}
      <div className="queryAns-container">
        <div className="ans">
          <img
            className="bot-icon conversation-profile"
            src={botIcon}
            alt={askDidiLogo}
          />
          <div className="ans-conversation-txt-container">
            <p className="conversation-txt">{initialMessage}</p>
          </div>
        </div>
      </div>

      {/* User queries and answers */}
      {query.map((question, index) => {
        const ans = answer[index];
        const plainTextAnswer = ans ? stripHtmlTags(ans) : "";

        return (
          <div className="queryAns-container" key={index}>
            <div className="query">
              <div className="query-conversation-txt-container">
                <img
                  className="speaker"
                  src={
                    isSpeakerClicked[index] && contentType === "query"
                      ? activeSpeakerIcon
                      : speakerIcon
                  }
                  alt="speaker"
                  onClick={() => handleSpeakerClick(question, index, "query")}
                />
                <p className="conversation-txt">{question}</p>
              </div>
              <img
                className="user-icon conversation-profile"
                src={userIcon}
                alt={userIcon}
              />
            </div>

            {ans !== undefined ? (
              <div className="ans">
                <img
                  className="bot-icon conversation-profile"
                  src={botIcon}
                  alt={askDidiLogo}
                />
                <div className="ans-conversation-txt-container">
                  <img
                    className="speaker"
                    src={
                      isSpeakerClicked[index] && contentType === "answer"
                        ? activeSpeakerIcon
                        : speakerIcon
                    }
                    alt="speaker"
                    onClick={() => handleSpeakerClick(ans, index, "answer")}
                  />
                  <div
                    className="conversation-txt"
                    dangerouslySetInnerHTML={{ __html: ans }}
                  />
                </div>
                {/* {showDownloadButton && (
    <button className="download-btn" onClick={() => handleDownload(ans)}>
        <img src="../../assets/images" alt="Download" className="download-icon" />
        
    </button>
)} */}
              </div>
            ) : (
              <div className="ans">
                <img
                  className="bot-icon conversation-profile"
                  src={botIcon}
                  alt={askDidiLogo}
                />
                {/* <div className="ans-conversation-txt-container">
                  <p className="conversation-txt">
                    Analyzing your query and fetching answer...
                  </p>
                </div> */}
                <div className="ans-conversation-txt-container">
                  <p className="conversation-txt">Analyzing your query</p>
                  <div className="spinner">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                    <div className="dot3"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default QueryAns;
