// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar-container{
    display: flex;
    align-items: center;
    background-color: white;
    border: 0.08rem solid var(--primary-color);
    border-radius: 0.4rem;
    width: 75%;
    height: 2.5rem;
    padding: 1rem;
}

.txt-input{
    border: none;
    outline: none;
    font-size: 1rem;
    width: 90%;
}

.searchbar-icon-container{
    display: flex;
    margin-left: auto;
}

#send-btn{
    margin-left: 1%;
    cursor: pointer;
}

#mic-btn{
    margin-left: 10%;
    cursor: pointer;
}

@keyframes pulsate {
    0%{
      transform: scale(1,1);
      opacity: 1;
    }
    100%{
      transform: scale(1.3, 1.3);
      opacity: 0;
    }
  }

.searchbar-icon.listening{
    width:4rem;
    height:4rem;
    border:1px solid black;
    background-color:red;
    padding: 5rem;
    animation: pulsate 1s infinite;
}



`, "",{"version":3,"sources":["webpack://./src/components/searchBar/searchBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,0CAA0C;IAC1C,qBAAqB;IACrB,UAAU;IACV,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,UAAU;AACd;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;MACE,qBAAqB;MACrB,UAAU;IACZ;IACA;MACE,0BAA0B;MAC1B,UAAU;IACZ;EACF;;AAEF;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,oBAAoB;IACpB,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".searchBar-container{\n    display: flex;\n    align-items: center;\n    background-color: white;\n    border: 0.08rem solid var(--primary-color);\n    border-radius: 0.4rem;\n    width: 75%;\n    height: 2.5rem;\n    padding: 1rem;\n}\n\n.txt-input{\n    border: none;\n    outline: none;\n    font-size: 1rem;\n    width: 90%;\n}\n\n.searchbar-icon-container{\n    display: flex;\n    margin-left: auto;\n}\n\n#send-btn{\n    margin-left: 1%;\n    cursor: pointer;\n}\n\n#mic-btn{\n    margin-left: 10%;\n    cursor: pointer;\n}\n\n@keyframes pulsate {\n    0%{\n      transform: scale(1,1);\n      opacity: 1;\n    }\n    100%{\n      transform: scale(1.3, 1.3);\n      opacity: 0;\n    }\n  }\n\n.searchbar-icon.listening{\n    width:4rem;\n    height:4rem;\n    border:1px solid black;\n    background-color:red;\n    padding: 5rem;\n    animation: pulsate 1s infinite;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
