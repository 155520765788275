import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Login.css";

const Login = ({ setIsAuthenticated }: { setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post("https://dms1.geotechnosoft.com/login", {
        email,
        password,
      });

      const { message } = response.data;

      if (message === "Login successful") {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("email", email);

           // Clear existing user data before fetching new data
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("userName");
      localStorage.removeItem("userRole");

        // Fetch user data immediately after successful login
        await fetchUserData(email);

        // Set session timeout
        const SESSION_TIMEOUT = 60 * 60 * 1000;
        const expiryTime = new Date().getTime() + SESSION_TIMEOUT;
        localStorage.setItem("expiryTime", expiryTime.toString());

        navigate("/"); // Redirect to home page after authentication
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred during the login process.");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async (email: string) => {
    try {
      const response = await axios.get(`https://dms1.geotechnosoft.com/get_user`, {
        params: { email }
      });
      const userData = response.data;
      if (userData.error) {
        throw new Error(userData.error);
      }
      // Store user data in local storage
      localStorage.setItem("firstName", userData.first_Name);
      localStorage.setItem("lastName", userData.last_Name);
      localStorage.setItem("userName", `${userData.first_Name} ${userData.last_Name}`);
      localStorage.setItem("userRole", userData.role);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  return (
    <div className="container">
      <div className="heading">Sign In</div>
      <form onSubmit={handleLogin} className="form">
        <input
          required
          className="input"
          type="email"
          name="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          required
          className="input"
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input className="login-button" type="submit" value={loading ? "Signing In..." : "Sign In"} disabled={loading} />
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default Login;